<template>
  <v-card-text>
    <v-data-table
      :headers="headers"
      :footer-props="footerProps"
      :items="users"
      :loading="isLoading"
      :server-items-length="usersCount"
      :options.sync="options"
    >
      <template v-slot:item.email="{ item }">
        <router-link
          class="view-link"
          :to="{ name: 'UsersEdit', params: { id: item.id } }"
        >
          {{ item.attributes.email }}
        </router-link>
      </template>
      <template v-slot:item.roles="{ item }">
        {{ getRolesString(item.relationships) }}
      </template>
      <template v-slot:item.isActive="{ item }">
        <v-icon v-if="item.attributes.isActive" color="green">
          mdi-check-circle
        </v-icon>
        <v-icon v-else color="red">mdi-cancel</v-icon>
      </template>
      <template v-slot:no-data>
        <no-data-alert />
      </template>
    </v-data-table>
  </v-card-text>
</template>

<script>
import { getBelongsToManyByType } from "@shared/util/Relationships.js";
import { map } from "lodash";
import { mapActions, mapState } from "vuex";
import NoDataAlert from "@admin/components/NoDataAlert.vue";
import _ from "lodash";

export default {
  name: "UsersIndex",
  data() {
    return {
      isLoading: false,
      headers: [
        {
          text: "Email",
          value: "email",
        },
        {
          text: "Roles",
          value: "roles",
          sortable: false,
        },
        {
          text: "Status",
          value: "isActive",
        },
      ],
      options: {
        page: 1,
        itemsPerPage: 20,
        sortBy: [],
        sortDesc: [],
      },
    };
  },
  computed: {
    ...mapState(["footerProps"]),
    ...mapState("users", ["users", "includedRelations", "usersCount"]),
    pageAndSortData() {
      const data = (({ page, itemsPerPage, sortBy }) => ({
        page,
        limit: itemsPerPage,
        sortBy,
      }))(this.options);

      if (this.options.sortBy.length) {
        data.sortBy = [];
        for (let i = 0; i < this.options.sortBy.length; i++) {
          const sortField = this.options.sortBy[i];
          const desc = this.options.sortDesc[i] === true ? "-" : "";
          data.sortBy.push(desc + sortField);
        }
      }

      return data;
    },
  },
  watch: {
    pageAndSortData: {
      async handler(currentValue, oldValue) {
        if (_.isEqual(currentValue, oldValue)) return;

        this.isLoading = true;
        await this.getUsers(currentValue);
        this.isLoading = false;
      },
      deep: true,
    },
  },
  components: {
    NoDataAlert,
  },
  methods: {
    ...mapActions("navigationBar", [
      "clearActionButtons",
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setTitleObject",
    ]),
    ...mapActions("users", ["getUsers", "clearUsers"]),
    getRolesString(relationships) {
      return map(
        getBelongsToManyByType(relationships, "roles", this.includedRelations),
        (obj) => obj?.attributes?.name
      ).join(", ");
    },
  },
  async beforeMount() {
    if (!this.users.length) {
      this.isLoading = true;
      await this.getUsers();
      this.isLoading = false;
    }
    this.setBreadcrumbs();
    this.setTitleObject("Users");
    this.setActionButtons([
      {
        attributes: {
          to: "/users/add",
        },
        component: "AddButton",
        text: "Add User",
        index: 0,
      },
    ]);
  },
  destroyed() {
    this.clearUsers();
    this.clearActionButtons();
    this.clearTitleObject();
  },
};
</script>

<style scoped></style>
